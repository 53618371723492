import React from "react";
import Typography from "@mui/material/Typography";

function ResultsFooter() {
  return (
    <>
      <Typography paragraph={true}>
        To discuss your requirements in more detail, please get in touch with
        one or more surveying firms of your choice.
      </Typography>
      <Typography paragraph={true}>
        As well as confirming the price and seeing how soon they can carry out
        your survey, ask for the name of the building surveyor who will be
        working for you and have them confirm that their qualifications,
        experience and insurance are appropriate for your type of property.
      </Typography>
      <Typography paragraph={true}>
        To get the most out of your survey, we recommend walking round with the
        surveyor. Check up front that your surveyor is happy for you to do this.
        Most surveyors are but some may wish to carry out the survey first, with
        a minimum of distraction, after which they will be happy to walk around
        the property with you.
      </Typography>
      <Typography paragraph={true}>
        An email with the above prices and contact details has been sent to you,
        so you can refer to it later at your convenience.
      </Typography>
      <Typography paragraph={true}>
        If you need more information or help in your house purchase, please call
        us on 0330 088 2050
      </Typography>
    </>
  );
}

export default ResultsFooter;
